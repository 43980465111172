import React from 'react';

import Layout from '../../components/LayoutSignup';
import SEO from '../../components/SEO';
import EmailForm from '../../components/EmailForm';
import Grid from '../../components/Grid';
import Cell from '../../components/Cell';
import P from '../../components/P';
import H3 from '../../components/H3';

const SignupPage = () => (
  <Layout>
    <SEO title="Signup" />

    <Grid pt="baseUp8" pb="baseUnit" halign="center">
      <Cell
        size={{ mobile: 1, smallTablet: 1 / 2 }}
        px={{ mobile: 'baseUnit', smallTablet: '0' }}
      >
        <H3 mb="baseUnit">Fyll i din e-post</H3>
        <P>
          Bokaplanen är en tjänst som gör det lika enkelt att boka träningstider
          som att lägga in ett möte i kalendern på din smartphone, så att du kan
          ägna mer tid åt din förening.
        </P>
        <P>
          Fyll i dina uppgifter här under, så kontaktar vi dig och hittar en tid
          för att visa hur Bokaplanen fungerar.
        </P>
      </Cell>
    </Grid>

    <Grid pt="baseUnit" pb="baseUp8" halign="center">
      <Cell
        size={{ mobile: 1, smallTablet: 1 / 2 }}
        px={{ mobile: 'baseUnit', smallTablet: '0' }}
      >
        <EmailForm disclaimerColor="baseFont" buttonLabel="Boka en demo" />
      </Cell>
    </Grid>
  </Layout>
);

export default SignupPage;
