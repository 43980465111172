import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import axios from 'axios';

import Block from './Block';
import Button from './Button';
import InputText from './InputText';
import P from './P';

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Vänligen fyll i ditt namn'),
    organization: Yup.string().required('Vänligen fyll i föreningens namn'),
    email: Yup.string()
      .email('Vänligen fyll i en korrekt e-postadress')
      .required('Vänligen fyll i en e-postadress'),
  });

const TextInput = styled(InputText)`
  border-radius: ${({ theme }) => theme.spacing.baseDown2};
`;

const CTAButton = styled(Button)`
  border-radius: ${({ theme }) => theme.spacing.baseDown2};
  font-size: ${({ theme }) => theme.spacing.baseUnit};
  white-space: nowrap;
  margin-left: auto;
`;

class EmailForm extends Component {
  state = {
    hasError: false,
  };

  onSubmit = async (values, { setSubmitting }) => {
    this.setState({
      hasError: false,
    });

    try {
      await axios({
        method: 'post',
        url: '/.netlify/functions/subscribe',
        data: {
          email_address: values.email,
          merge_fields: {
            ORG: values.organization,
            NAME: values.name,
          },
        },
      });

      setSubmitting(false);

      navigate('/signup/thanks');
    } catch (err) {
      setSubmitting(false);
      this.setState({
        hasError: true,
      });
    }
  };

  render() {
    const { buttonLabel } = this.props;
    const { hasError } = this.state;

    return (
      <Formik
        initialValues={{ name: '', organization: '', email: '' }}
        validationSchema={validationSchema()}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Block>
                  <Block display="flex" flexDirection="column">
                    <Block mb="baseUnit">
                      <P mb="0">Namn</P>
                      <TextInput
                        type="name"
                        name="name"
                        placeholder="Fyll i ditt namn"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        hasError={errors.name && touched.name && errors.name}
                      />
                      {errors.name && touched.name && (
                        <P color="error">{errors.name}</P>
                      )}
                    </Block>

                    <Block mb="baseUnit">
                      <P mb="0">Förening</P>
                      <TextInput
                        type="organization"
                        name="organization"
                        placeholder="Fyll i vilken förening du representerar"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organization}
                        hasError={
                          errors.organization &&
                          touched.organization &&
                          errors.organization
                        }
                      />

                      {errors.organization && touched.organization && (
                        <P color="error">{errors.organization}</P>
                      )}
                    </Block>

                    <Block mb="baseUnit">
                      <P mb="0">E-post</P>
                      <TextInput
                        type="email"
                        name="email"
                        placeholder="Fyll i din e-postadress"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        hasError={errors.email && touched.email && errors.email}
                      />
                      <P fontSize="baseDown1" mb="0">
                        Vi använder bara din epost till att kontakta dig.
                      </P>

                      {errors.email && touched.email && (
                        <P color="error">{errors.email}</P>
                      )}
                    </Block>

                    <CTAButton
                      type="submit"
                      backgroundColor="green"
                      p="baseUnit"
                      disabled={isSubmitting}
                    >
                      {buttonLabel}
                    </CTAButton>
                  </Block>

                  {hasError ? (
                    <P color="green">Ett fel uppstod. Försök igen snart.</P>
                  ) : null}
                </Block>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

EmailForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
};

export default EmailForm;
