import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import HeaderLogo from './HeaderLogo';
import Container from './Container';
import ContainerHeader from './ContainerHeader';

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

const SiteHeaderSignup = () => (
  <Container>
    <ContainerHeader
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px="baseUnit"
    >
      <LogoLink to="/">
        <HeaderLogo color="green" />
      </LogoLink>
    </ContainerHeader>
  </Container>
);

SiteHeaderSignup.propTypes = {};

export default SiteHeaderSignup;
