import styled from 'styled-components';
import { rgba } from 'polished';

import { margins } from '../ui/utils';

const InputText = styled.input.attrs({
  type: 'text',
})`
  border: 2px solid
    ${({ theme, hasError }) =>
      rgba(hasError ? theme.colors.error : theme.colors.green, 0.4)};
  font-family: ${({ theme }) => theme.fontFamily.base};
  font-size: ${({ theme }) => theme.spacing.baseUnit};
  padding: ${({ theme }) => theme.spacing.baseUnit};
  border-radius: ${({ theme }) => theme.spacing.baseDown2};
  width: 100%;
  outline: none;
  transition: box-shadow 0.2s ease-in;

  ${props => margins(props)};

  &:focus {
    box-shadow: 0 0 12px 0
      ${({ theme, hasError }) =>
        rgba(hasError ? theme.colors.error : theme.colors.green, 0.3)};
  }
`;

export default InputText;
