import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import theme from '../ui/theme';

import GlobalStyle from './GlobalStyle';
import SiteHeaderSignup from './SiteHeaderSignup';
import CookieBanner from './CookieBanner';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />

      <SiteHeaderSignup />

      <main style={{ flex: 1 }}>{children}</main>

      <CookieBanner />
    </>
  </ThemeProvider>
);

Layout.defaultProps = {};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
